import { render, staticRenderFns } from "./slide.vue?vue&type=template&id=49205f92&scoped=true&"
import script from "./slide.vue?vue&type=script&lang=js&"
export * from "./slide.vue?vue&type=script&lang=js&"
import style0 from "./slide.vue?vue&type=style&index=0&id=49205f92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49205f92",
  null
  
)

component.options.__file = "slide.vue"
export default component.exports